import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import queryString from 'query-string'
import { firebase } from '@utils/firebase'

const LoginPage = (props) => {
  const [mailAddress, setMailAddress] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        navigate(
          "/app/analyze",
          { replace: true }
        )
      }
    });

    return function cleanup() {
      if (unsubscribe) {
        unsubscribe();
      }
    }
  });

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'email':
        setMailAddress(event.target.value);
        break;
      case 'password':
        setPassword(event.target.value);
        break;
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
      return firebase.auth().signInWithEmailAndPassword(mailAddress, password);
    }).catch(function(error) {
      console.log(error, 'login error');
    });
  }

  return (
    <div className="container is-fluid">
      <section className="section has-padding-bottom-10">
        <div className="container has-text-centered">
          <h2 className="title">みまもりアンケート管理</h2>
        </div>
      </section>

      <section className="section">
        <div className="container has-text-centered">
          <div className="columns is-centered">
            <div className="column is-5 is-4-desktop">
              <form onSubmit={handleSubmit}>
                <div className="field">
                  <div className="control">
                    <input className="input" type="email" name="email" placeholder="メールアドレス" onChange={handleChange} />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <input className="input" type="password" name="password" placeholder="パスワード" onChange={handleChange} />
                  </div>
                </div>
                <div className="field">
                  <button className="button is-primary is-fullwidth">ログイン</button>
                </div><a href="#">パスワードを忘れた方はこちら</a>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default LoginPage
