import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "@components/routes/PrivateRoute"
import AnalyzePage from "@components/pages/analyze/AnalyzePage"
import LoginPage from "@components/pages/LoginPage"
const App = () => (
  <Router>
    <PrivateRoute path="/app/analyze" component={AnalyzePage} />
    <LoginPage path="/app/login" />
  </Router>
)
export default App