
import React, { Component, useState, useEffect } from "react"
import { navigate } from "gatsby"
import { firebase } from '@utils/firebase'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const loggedIn = firebase.auth().currentUser;
  if (!loggedIn && location.pathname !== "/app/login") {
    navigate("/app/login")
    return null
  }
  return <Component {...rest} />
}
export default PrivateRoute
