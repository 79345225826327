import firebase from "firebase/app"
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'
import 'firebase/database'

const config = {
  apiKey: "AIzaSyCX_FGHwturiBxv6rLq5u47quRr9pUi7m8",
  authDomain: "mmr-stg-enquete-260307.firebaseapp.com",
  databaseURL: "https://mmr-stg-enquete-260307.firebaseio.com",
  projectId: "mmr-stg-enquete-260307",
  storageBucket: "mmr-stg-enquete-260307.appspot.com",
  messagingSenderId: "851044110864",
  appId: "1:851044110864:web:a06e9f07a065a931953095",
  measurementId: "G-FYXVE5BVZR"
};

let functions = null;
if (!firebase.apps.length) {
  const fireApp = firebase.initializeApp(config);
  functions = fireApp.functions('asia-northeast1');
  // functions.useFunctionsEmulator('http://localhost:5000');
}
export {firebase, functions}
