import { firebase } from '@utils/firebase'
const storage = firebase.storage();

const getTemplate = (enqueteId) => {
  return firebase.storage().ref(`enquete-template/${enqueteId}.json`).getDownloadURL().then( url => {
    return fetch(url);
  }).then(response => {
    if (response.status !== 200) {
      throw new Error('Enquete Template Unresolved.');
    }
    return response.json();
  });
}

export default {
  getTemplate
}