import React, { useState, Suspense, useEffect } from "react"
import { navigate } from "gatsby"
import { firebase } from "@utils/firebase"
import AnalyzeGraph from "./AnalyzeGraph"
import * as csv from "csv";
import * as downloadjs from "downloadjs"
import EnqueteService from "@service/EnqueteService"

const AnalyzePage = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [enqueteId, setEnqueteId] = useState(null);
  return (
    <div>
      <div className="container">
        <label className="label">調査対象のアンケートIDを入力してください</label>
        <div className="field is-grouped">
          <div className="control">
            <input className="input" type="text" placeholder="アンケートID" onChange={(event) => { setInputValue(event.target.value) }} />
          </div>
          <div className="control">
            <button className="button is-link is-light" onClick={() => {setEnqueteId(inputValue)}}>表示</button>
          </div>
          <div className="control">
            <button className="button is-link is-light" onClick={() => downloadCsv(inputValue)}>CSVダウンロード</button>
          </div>
        </div>
      </div>

      { enqueteId && <AnalyzeGraph enqueteId={enqueteId} /> }
    </div>
  )
}

async function downloadCsv(enqueteId) {
  const template = await EnqueteService.getTemplate(enqueteId);
  console.log(template, 'template')
  const columns = template.questions.map(item => item.name);

  const ref = firebase.database().ref('enquetes/' + enqueteId + '/answers');
  ref.once('value', (snapshot) => {
    const records = [];
    snapshot.forEach(s => {
      const answer = s.val();
      const data = {};
      // 質問タイプに応じてレコードを作成する
      for (const q of template.questions) {
        switch(q.type) {
          case 'text':
            data[q.name] = answer[q.name];
            break;
          case 'radio':
            data[q.name] = answer[q.name].text;
            break;
          case 'checkbox':
            data[q.name] = answer[q.name].map(item => item.freeInput ? item.freeInput : item.text).join(':')
            break;
          case 'textarea':
            data[q.name] = answer[q.name];
            break;
        }
      }
      records.push(data);
    });

    csv.stringify(records, { header: true, columns: columns }, function(err, output){
      console.log(output);
      downloadjs(new Blob(["\ufeff" + output]), `${enqueteId}.csv`, "text/csv")
    });

  }, (error) => {
    console.log(error, 'database fetch error.');
  });
}

export default AnalyzePage
