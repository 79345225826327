import React, { useState, Suspense, useEffect } from "react"
import { navigate } from "gatsby"
import queryString from "query-string"
import { firebase } from "@utils/firebase"
import { Chart } from "react-google-charts"

import EnqueteService from "@service/EnqueteService"

class EnqueteSummary {
  constructor(template) {
    this.template = template;
    this.questions = [];
    for (let t of this.template) {
      this.questions.push(new EnqueteQuestion(t));
    }
  }

  addAnswer(questionName, answer) {
    for (let q of this.questions) {
      if (q.name !== questionName) {
        continue
      }

      if (q.type === 'radio') {
        if (answer.freeInput) {
          q.addAnswer(answer.freeInput)
        } else {
          q.addAnswer(answer.text);
        }
      }
      else if (q.type === 'checkbox') {
        for (const checkedItem of answer) {
          if (checkedItem.freeInput) {
            q.addAnswer(checkedItem.freeInput)
          } else {
            q.addAnswer(checkedItem.text)
          }
        }
      }
      else if (q.type === 'textarea') {
        q.addAnswer(answer)
      }
      else if (q.type === 'text') {
        q.addAnswer(answer)
      }
    }
  }

  getSummary() {
    return this.questions.map(q => {
      return q.getSummary();
    });
  }
}

class EnqueteQuestion {
  constructor(questionTemplate) {
    this.type = questionTemplate.type;
    this.name = questionTemplate.name;
    this.title = questionTemplate.title;
    this.options = questionTemplate.options;
    this.answerCounts = {};

    if (this.type == 'radio') {
      this._initializeOptionsAnswer();
    }
  }

  addAnswer(answer) {
    let value = answer;
    if (this.answerCounts[value]) {
      this.answerCounts[value]++;
    } else {
      this.answerCounts[value] = 1;
    }
  }

  getSummary() {
    return {
      type: this.type,
      name: this.name,
      title: this.title,
      summary: this.answerCounts
    };
  }

  _initializeOptionsAnswer() {
    for (let option of this.options) {
      if (option.freeOption | option.freeInput) {
        continue;
      }
      this.answerCounts[option.text] = 0;
    }
  }
}

const AnalyzePage = (props) => {
  const [template, setTemplate] = useState([]);
  const [graphDataArray, setGraphDataArray] = useState([]);
  const enqueteId = props.enqueteId;

  useEffect(() => {
    EnqueteService.getTemplate(enqueteId).then(template => {
      setTemplate(template.questions);
    });
  }, [enqueteId])

  useEffect(() => {
    if (template.length <= 0) {
      return;
    }

    const ref = firebase.database().ref('enquetes/' + enqueteId + '/answers');
    ref.on('value', (snapshot) => {

      // データの例
      // let result = [
      //   ['Question', 'Q1'], ['xxxxなら利用する', 10], ['yyyyなら利用する', 20]
      // ];
      const summary = new EnqueteSummary(template);
      snapshot.forEach(s => {
        const answer = s.val();
        for (let questionName of Object.keys(answer)) {
          const answerValue = answer[questionName];
          summary.addAnswer(questionName, answerValue);
        }
      });
      
      const numberRegex = /.+?([0-9]+).*/;
      const graphDataArray = [];
      const summaryDataPerQuestion = summary.getSummary();
      for (let summaryData of summaryDataPerQuestion) {
        const graphBaseData = [[summaryData.title, '集計結果']];
        const graphData = [];
        for (let answerNumber of Object.keys(summaryData.summary)) {
          const answerCount = summaryData.summary[answerNumber];
          graphData.push([answerNumber, answerCount]);
        }

        // 月額料金値に応じてソートする
        graphData.sort((a, b) => {
          const aMatch = numberRegex.exec(a[0]);
          const bMatch = numberRegex.exec(b[0]);
          let aValue = 0;
          let bValue = 0;
          if (aMatch) {
            aValue = parseInt(aMatch[1]);
          }
          if (bMatch) {
            bValue = parseInt(bMatch[1]);
          }
          return (aValue > bValue) ? -1 : 1;
        });
        graphDataArray.push({type: summaryData.type, title: summaryData.title, data: graphBaseData.concat(graphData)});
      }

      // console.log(graphDataArray);
      setGraphDataArray(graphDataArray);
    }, (error) => {
      console.log(error, 'database fetch error.');
    });

    return () => {
      ref.off();
    }
  }, [template])

  return (
    <div style={{margin: 30}}>
    {
      graphDataArray.length > 0 &&
      graphDataArray.map(graphData => {
        if (graphData.type === 'textarea' || graphData.type === 'text') {
          const elements = graphData.data.map((textAreaData, index) => {
            return <div key={textAreaData[0]} className={index === 0 ? 'has-text-weight-bold': ''}>
                      {textAreaData[0]}
                    </div>
          })
          return <div key={graphData.title} style={{paddingLeft: 240}}>
                  {elements}
                 </div>
        }
 
        return <Chart
                  key={graphData.title}
                  chartType="BarChart"
                  data={graphData.data}
                  graph_id={graphData.title}
                  height={300}
                  width='100%'
                  options={{
                    title: graphData.title,
                    legend: { position: "none" },
                    hAxis: {format: '0'},
                    chartArea: {left: 240, height: '80%'}

                  }}
                />
      })
    }
    </div>
  )
}

export default AnalyzePage
